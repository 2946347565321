<template>
  <div class="my">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name:"My"
}
</script>
<style lang="less" scoped>

</style>